import { Navigate, Outlet, type RouteObject } from 'react-router-dom'
import { BaseTrinityWrapper, ErrorBoundary } from '@trinity/components'
import { Box } from '@mui/material'
import { action as publicStylepageAction, loader as publicStylepageLoader, PublicStylepage } from './stylepages.$uuid'
import { loader as publicStylepageItemLoader, StylepageItem } from './stylepages.$uuid.$rackId'

export const publicRoutes: RouteObject = {
  path: 'public',
  errorElement: <ErrorBoundary />,
  element: (
    <BaseTrinityWrapper appName='dealer'>
      <Box p={4}>
        <Outlet />
      </Box>
    </BaseTrinityWrapper>
  ),
  children: [
    { index: true, element: <Navigate replace to='stylepages' /> },
    {
      path: 'stylepages/:uuid',
      id: 'publicStylepage',
      loader: publicStylepageLoader,
      children: [
        {
          index: true,
          element: <PublicStylepage />,
          loader: publicStylepageLoader,
          action: publicStylepageAction,
        },
        {
          path: ':rackId',
          element: <StylepageItem />,
          loader: publicStylepageItemLoader,
        },
      ],
    },
    {
      path: '*',
      element: <h1>Page Not Found</h1>,
    },
  ],
}
